<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.orderNo" placeholder="订单编号"/>
      </a-form-item>
      <a-form-item label="交易类型">
        <a-select v-model="form.transactionType" placeholder="请选择" style="width:180px;">
          <a-select-option :value='item.code' v-for="(item, index) in typeList" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否入账">
        <a-select v-model="form.increase" placeholder="请选择" style="width:180px;">
          <a-select-option :value=0>否</a-select-option>
          <a-select-option :value=1>是</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
     :data-source="list"
     :rowKey="record => record.id"
     :columns="columns"
     :pagination="pagination"
     bordered
    >
      <span slot="action1" slot-scope="scope">
        <div>{{scope.id}}</div>
        <div style="color:#1890ff;">{{ scope.statusName }}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <div><span style="color:red;"> {{scope}} </span></div>
      </span>
      <span slot="action3" slot-scope="scope">
        <div><span style="color:red;"> {{scope}} </span></div>
      </span>
      <span slot="action4" slot-scope="scope">
        <div>流水号:{{scope.serialNo}}</div>
        <div v-if="scope.increase==='0'">原始流水号:{{scope.oldSerialNo}}</div>
        <div>订单号:{{scope.orderNo}}</div>
      </span>
      <span slot="action5" slot-scope="scope">
        <div v-if="scope.detail!=''">{{scope.detail}}</div>
      </span>
      <span slot="action6" slot-scope="scope">
        <div>创建时间:{{scope.gmtCreate}}</div>
        <div v-if="scope.status==='complete'">入账时间:{{scope.gmtSettled}}</div>
      </span>
    </a-table>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: this.$route.params.breadName,
          url: '/account/' + this.$route.params.accountType + '/list'
        },
        {
          name: '明细管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      typeList: [],
      account: {
        accountId: this.$route.params.accountId,
        accountType: this.$route.params.accountType,
        breadName: this.$route.params.breadName
      },
      form: {
        increase: '',
        orderNo: '',
        transactionType: ''
      },
      columns: [
        { title: '编号', width: 120, key: 'id', scopedSlots: { customRender: 'action1' } },
        { title: '账户编号', width: 120, key: 'accountId', dataIndex: 'accountId' },
        { title: '交易类型', width: 120, key: 'transactionTypeName', dataIndex: 'transactionTypeName' },
        {
          title: '交易金额',
          width: 90,
          key: 'amountChange',
          dataIndex: 'amountChange',
          scopedSlots: { customRender: 'action2' }
        },
        { title: '当前余额', width: 90, key: 'deposit', dataIndex: 'deposit', scopedSlots: { customRender: 'action3' } },
        { title: '流水号', width: 240, key: 'serialNo', scopedSlots: { customRender: 'action4' } },
        { title: '详情', width: 180, key: 'detail', scopedSlots: { customRender: 'action5' } },
        { title: '时间', width: 240, key: 'gmtCreate', scopedSlots: { customRender: 'action6' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.account = {
      accountId: this.$route.params.accountId,
      accountType: this.$route.params.accountType,
      breadName: this.$route.params.breadName
    }
    this.reloadList()
    this.queryTypeList()
  },
  methods: {
    async queryTypeList () {
      const res = await this.$store.dispatch('http', {
        api: 'recordTypeList'
      })
      if (res) {
        const that = this
        for (var key in res) {
          that.typeList.push({ code: key, name: res[key] })
        }
      }
    },
    // 重置表单
    resetForm (formName) {
      this.form.increase = ''
      this.form.orderNo = ''
      this.form.transactionType = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        accountId: this.account.accountId
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryAccountRecord',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    }
  }
}
</script>

<style lang="scss" module>
  .container {
    .form__ct {
      padding: 10px 0;
    }

    .form__items {
      padding-top: 6px;
    }
  }
</style>
